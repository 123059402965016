import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PesentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2021_10_05_STI-testing-POC.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

  return (
  <StyledContainer>
    	<WebinarBody
			viewAllowed={props.viewAllowed}
    	>
    	<div className="flexBox">
			<div className="block block--left">
				  <div className="block__content">
					<h2 data-aos="fade-up">Overview</h2>
					<p data-aos="fade-up">In this on-demand video, infectious disease expert Gary Schoolnik, MD will present recent advances in 
					nucleic acid amplification testing (NAAT) methodology, and instrument-free single-use PCR 
					(polymerase chain reaction) in particular, that provides both accuracy and speed of results at the point of care.
					Such advancements for STI enables clinicians to make results-driven diagnoses for correct treatment, all while 
					the patient is till at the clinic visit, for a more focused clinician-patient dialogue and teachable moment.
					</p>

					<h2 data-aos="fade-up">Learning Objectives</h2>
					<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
					<ul data-aos="fade-up">
						<li>Review the rising rates of Sexually Transmitted Infections (STI)</li>
						<li>Analyze today’s STI testing methods</li>
						<li>Discuss the challenges and implications of today’s testing methods: for the patient, clinician, and public health.</li>
						<li>Examine the criteria for POC testing that could improve patient and clinician satisfaction scores and positively impact STI rates.</li>
					</ul>
				  </div>
				</div>
			
			   <div className="block block--right">
				   <div className={ (props.pageName==='display' | props.viewAllowed===false ) ? "accessBox show" : "accessBox" }>
						<h3 data-aos="fade-up">Access Includes:</h3>
						<ul data-aos="fade-up">
							<li>Video recording replay</li>
							<li>Presentation Slides (PDF)</li>
						</ul>
					</div>
				   <div className={ (props.pageName==='download') ? "downloadBox show showPDF" : "downloadBox" }>
						<h3 data-aos="fade-up">Download</h3>
						<p data-aos="fade-up"><a href={PesentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
					</div>
				</div>
			</div>

			<div className="block block--left">
			  <div className="block__content presenter">
				<h2  data-aos="fade-up">About the Presenter</h2>
				
				<div className="presenterBio" data-aos="fade-up">
				<StaticImage
					src="../../../../assets/images/webinar-replay/gary-schoolnik.jpg"
					width={200}
					alt="Dr. Gary Schoolnik"
					className="presenter__image"
				/>
				<p data-aos="fade-up">Dr. Gary Schoolnik is Chief Medical Officer, Visby Medical. He is also Professor of Medicine (emeritus), Stanford Medical School, 
				Attending Physician in Internal Medicine and Infectious Diseases at Stanford University Hospital and Associate 
				Director of Stanford’s Institute for Immunology, Transplantation and Infection.</p>

				<p data-aos="fade-up">Dr. Schoolnik received his M.D. degree and infectious diseases subspecialty training at the University of Washington in Seattle, served 
				as medical intern, resident and chief resident at Harvard’s Massachusetts General Hospital in Boston and he was a research associate 
				and associate physician at the Rockefeller University in Manhattan. While on the Stanford Medical School faculty he served as Chief 
				of the Division of Infectious Diseases and Geographic Medicine. National service included his appointment to the 
				Advisory Council of the National Institutes of Allergy and Infectious Diseases of the NIH and his role as chair of the 
				NIH Trans-Institute Committee on the Human Microbiome and co-chair of the NIH Blue Ribbon Committee on Bioterrorism. 
				International consultative activities include the Board of Scientific Counselors of Fudan University in Shanghai. He was founding 
				editor of the journal Molecular Microbiology and Associate Editor of the Journal of Clinical Investigation. </p>

				<p data-aos="fade-up">His academic research focuses on the molecular, genetic and genomic aspects of infectious agents and on the 
				development of new drugs, vaccines and diagnostics. Gary has 6 issued patents.</p>

				<p data-aos="fade-up">Full lists of publications: visit <a href="https://profiles.stanford.edu/gary-schoolnik" target="_blank" rel="noopener noreferrer">https://profiles.stanford.edu/gary-schoolnik</a></p>
				</div>
			</div>
		</div>
    	</WebinarBody>
    </StyledContainer>
  )
}

class FullBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewAllowed: false
    }
  }

  componentDidMount = props=> {
  if(localStorage.getItem(this.props.localStorageID)) {
		this.setState({
				viewAllowed: true
			})
		}
	}

  render = () => (
    <BodyLanding
		pageName={this.props.pageName}
		viewAllowed={this.state.viewAllowed}
		localStorageID={this.props.localStorageID}
    />
  )
}

export default FullBody
