import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import FormLanding from "./../screens/webinar-replay/2021-10-05-schoolnik/form-landing"
import BodyLanding from "./../screens/webinar-replay/2021-10-05-schoolnik/body-landing"

const SchoolnikReplay = () => {
  return (
    <React.Fragment>
      <FormLanding />
      <BodyLanding pageName="display" localStorageID="2021-10-05-schoolnik-webinar-replay" />
    </React.Fragment>
  )
}

export default SchoolnikReplay

export const Head = () => (
  <Seo
        title="Webinar Replay: Advances in PCR for POCT to Address the STI Infection Epidemic"
        description="Presenter Dr. Gary Schoolnik talks about Advances in PCR for POCT to Address the Sexually Transmitted Infection Epidemic."
        image="/meta/webinar-schoolnik-2021-10-05.jpg"
        recaptcha
  />
)
